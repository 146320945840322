import Form from "../components/registration/register-form/Form";

const Register = () => {
  return (
    <>
      <Form />
    </>
  );
};

export default Register;
