import React from "react";

const AboutHeader = () => {
  return (
    <div>
      <div className="p-4 text-center">
        <h4 className="font-serif text-3xl my-3 text-main_light underline">
          About ultra capital
        </h4>
        <p>
          ultra capital is one of the leading trading platforms,offering trading
          capacities, trade industry
        </p>
      </div>
    </div>
  );
};

export default AboutHeader;
