import Form from "../components/login/login-form/Form";

const Login = () => {
  return (
    <>
      <Form />
    </>
  );
};

export default Login;
